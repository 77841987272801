<script setup>
import {
	computed,
	ref,
	onMounted,
	onBeforeUnmount,
} from 'vue';
import {
	getStoreProducts,
	getVariantsQuantity,
} from '@zyro-inc/site-modules/api/StoreApi';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { getStoreId } from '@zyro-inc/site-modules/utils/getters/getStoreId';
import { isAppPrerendering } from '@zyro-inc/site-modules/utils/prerenderingFlags';

import BlockEcommerceProductList from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProductList.vue';
import { useBlockEcommerceProductList } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProductList';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@zyro-inc/site-modules/components/ecommerce/useEcommerce';
import { PRODUCT_TYPE_BOOKING } from '@zyro-inc/site-modules/constants/ecommerce';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';

const props = defineProps({
	blockId: {
		type: String,
		required: true,
	},
	data: {
		type: Object,
		default: () => ({}),
	},
	lcp: {
		type: Object,
		default: () => ({}),
	},
	ecommerceTranslations: {
		type: Object,
		default: () => ({}),
	},
	currentLocale: {
		type: String,
		default: SYSTEM_LOCALE,
	},
	blocks: {
		type: Object,
		default: () => ({}),
	},
	isCartVisible: {
		type: Boolean,
		default: false,
	},
	isInPreviewMode: {
		type: Boolean,
		default: false,
	},
});

const {
	meta,
	siteId,
} = useSiteGlobal();

const {
	isLoading: isEcommerceLoading,
	productPages,
	isShoppingCartOpen,
	shoppingCartItems,
	setIsLoading,
	setIsLoaded,
	setShoppingCartOpen,
	setShoppingCartItems,
	setIsCheckoutLoading,
	setSelectedBookingId,
} = useEcommerceGlobal({
	blockId: props.blockId,
});
const {
	openEcommerceModal,
	closeEcommerceModal,
	setProductPreviewData,
} = useEcommerceModal();
const { initiateCheckout } = useEcommerce(props);

const pageCount = ref(0);
const currentPage = ref(1);
const products = ref([]);
const variantsQuantity = ref([]);
const sorting = ref(props.data.productSorting?.enabled
	? props.data.productSorting?.sortingOptions?.find(({ isEnabled }) => isEnabled)?.value || ''
	: '');

const productIds = computed(() => props.blocks[props.blockId]?.productIds);

const {
	blockStyle,
	textColorVars,
	columnCount,
	productsPerPage,
	productCategoryId,
	isButtonEnabled,
	buttonDisplay,
	buttonText,
	buttonStyle,
	buttonType,
	buttonBorderWidth,
	ribbonStyle,
	imageRatio,
	productSorting,
	imageHoverEffect,
} = useBlockEcommerceProductList(props);

const fetchProducts = async ({
	sort,
	page,
}) => {
	const storeId = getStoreId(meta.value);

	if (!storeId) {
		return;
	}

	// !IMPORTANT. Explanation is in useEcommerceGlobal.ts fetchProducts function
	setIsLoaded(false);
	setIsLoading(true);

	try {
		const productsResponse = await getStoreProducts(storeId, {
			productIds: productIds.value,
			offset: (page - 1) * productsPerPage.value,
			limit: productsPerPage.value,
			collectionId: productCategoryId.value,
			sort: (!sort && productCategoryId.value) ? 'order=ASC&sort_by=collection_order' : sort,
		});
		const quantity = await getVariantsQuantity(storeId, productIds.value);

		products.value = productsResponse.products;
		variantsQuantity.value = quantity;
		pageCount.value = Math.ceil(productsResponse.count / productsResponse.limit);
	} catch (error) {
		console.error(error);
	} finally {
		setIsLoading(false);
		setIsLoaded(true);
	}
};

const isClientLoaded = ref(false); // needed for astro sites to not flash loader
const blockStorePageQuery = computed(() => `store-page-${props.blockId}`);

const isProductListShown = computed(() => !!products.value?.length);
const isLoading = computed(() => isAppPrerendering || isEcommerceLoading.value || !isClientLoaded.value);

const handlePageChange = (page) => {
	currentPage.value = page;
	fetchProducts({
		page,
		sort: sorting.value,
	});
};

const handleSortChange = (event) => {
	const sortType = event.target.value;

	sorting.value = sortType;
	fetchProducts({
		sort: sortType,
		page: currentPage.value,
	});
};

const handleButtonClick = async (product) => {
	if (props.isInPreviewMode) {
		openEcommerceModal('EcommerceMessageButtonDisabled');

		return;
	}

	if (product.options.length) {
		const itemProductPage = Object.values(productPages.value).find((page) => page.productId === product.id);
		const ecommerceBlocks = Object.keys(props.blocks).filter((key) => props.blocks[key].type === 'BlockEcommerceProduct');
		const pageProductBlockId = ecommerceBlocks.find((block) => itemProductPage.blocks.includes(block));

		if (!pageProductBlockId) {
			if (!itemProductPage) {
				window.location.assign('/');

				return;
			}

			window.location.assign(`/${itemProductPage.slug}`);

			return;
		}

		setProductPreviewData(props.blocks[pageProductBlockId]);
		openEcommerceModal('EcommerceProductPreview');

		return;
	}

	const productForShoppingCart = [
		{
			...product,
			variants: [product.variants[0]],
		},
	];

	await closeEcommerceModal();

	if (product.type.value === PRODUCT_TYPE_BOOKING) {
		setSelectedBookingId(product.id);
		openEcommerceModal('EcommerceBookingEventSelect');

		return;
	}

	if (props.isCartVisible) {
		setShoppingCartItems([
			...shoppingCartItems.value,
			...productForShoppingCart,
		]);

		if (isShoppingCartOpen.value) {
			return;
		}

		setShoppingCartOpen(true);
	} else {
		setIsCheckoutLoading(true);
		await initiateCheckout(productForShoppingCart).then(() => {
			setIsCheckoutLoading(false);
		});
	}
};

const handleBrowserNavigationPageChange = () => {
	const params = new URLSearchParams(window.location.search);
	const pageParam = params.get(blockStorePageQuery.value) || '1';
	const pageFromParams = Number.parseInt(pageParam, 10);

	if (pageFromParams === currentPage.value) {
		return;
	}

	currentPage.value = pageFromParams;
};

onMounted(() => {
	isClientLoaded.value = true;

	const params = new URLSearchParams(window.location.search);
	const pageParam = params.get(blockStorePageQuery.value) || '1';
	const pageFromParams = Number.parseInt(pageParam, 10);

	if (pageFromParams !== currentPage.value) {
		currentPage.value = pageFromParams;

		handlePageChange(pageFromParams);
	}

	window.addEventListener('popstate', () => {
		handleBrowserNavigationPageChange();
	});
});

onBeforeUnmount(() => {
	window.removeEventListener('popstate', handleBrowserNavigationPageChange);
});

fetchProducts({
	sort: sorting.value,
	page: currentPage.value,
});
</script>

<template>
	<BlockEcommerceProductList
		:block-id="blockId"
		:block-style="blockStyle"
		:text-color-vars="textColorVars"
		:is-product-list-shown="isProductListShown"
		:products-per-page="productsPerPage"
		:column-count="columnCount"
		:page-count="pageCount"
		:current-page="currentPage"
		:product-pages="productPages"
		:product-category-id="productCategoryId"
		:is-button-enabled="isButtonEnabled"
		:button-display="buttonDisplay"
		:button-text="buttonText"
		:button-style="buttonStyle"
		:button-type="buttonType"
		:button-border-width="buttonBorderWidth"
		:is-loading="isLoading"
		:ribbon-style="ribbonStyle"
		:products="products"
		:translations="ecommerceTranslations"
		:image-ratio="imageRatio"
		:image-hover-effect="imageHoverEffect"
		:is-eager="lcp.type === 'block-ecommerce-product-list' && lcp.id === blockId"
		:site-id="siteId"
		:variants-quantity="variantsQuantity"
		:product-sorting="productSorting"
		:sorting="sorting"
		@page-changed="handlePageChange"
		@sort-changed="handleSortChange"
		@button-click="handleButtonClick"
	/>
</template>
